/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Typography } from "@material-ui/core";
import { useScrollState } from "../../hooks/useScrollState";

import "./SideNavbar.css";

export const SideNavbar = () => {
  const scrollPos = useScrollState();

  return (
    <nav>
    <a href="#" 
      className={scrollPos === 0 ? 'active' : ''}
    >
      <Typography>
        Home
      </Typography>
    </a>
    <a href="#about" 
      className={scrollPos === 1 ? 'active' : ''}
    >
      <Typography>
        About
      </Typography>
    </a>
    <a href="#works"
      className={scrollPos === 2 ? 'active' : ''}
    >
      <Typography>
        Projects
      </Typography>
    </a>
    <a href="#contact"
      className={scrollPos === 3 ? 'active' : ''}
    >
      <Typography>
        Contact
      </Typography>
    </a>
  </nav>
  );
};