import React, { lazy, Suspense } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { HelmetMeta } from "./HelmetMeta";
import { ThemeProvider } from "../components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import { logCredits } from "../utils/logCredits";

import { Home } from "../pages/Home";
import { Loader } from "../pages/Loader";

// const Resume = lazy(() => import("../pages/Resume"));
const PageNotFound = lazy(() => import("../pages/PageNotFound"));
const UsbCable = lazy(() => import("../pages/blog/UsbCable/UsbCable"));
const CustomKeyboard = lazy(() => import("../pages/blog/CustomKeyboard/CustomKeyboard"));
const ReactPortfolio = lazy(() => import("../pages/blog/ReactPortfolio/ReactPortfolio"));
const IBMInternship = lazy(() => import("../pages/blog/IBMInternship/IBMInternship"));

export const App = () => {
    logCredits();

    return (
      <ThemeProvider>
        <CssBaseline />
          <Router>
            {/* <HelmetMeta /> */}
              <Switch>
                  <Route path="/" exact component={Home} />
                  <Route exact path="/blog/usb-cable" >
                    <Suspense fallback={<Loader />}>
                      <UsbCable />
                    </Suspense>
                  </Route>
                  <Route path="/blog/custom-keyboard">
                    <Suspense fallback={<Loader />}>
                      <CustomKeyboard/>
                    </Suspense>
                  </Route>
                  <Route path="/blog/react-portfolio">
                    <Suspense fallback={<Loader />}>
                      <ReactPortfolio/>
                    </Suspense>
                  </Route>
                  <Route path="/blog/ibm-internship">
                    <Suspense fallback={<Loader />}>
                      <IBMInternship/>
                    </Suspense>
                  </Route>
                  <Route path="*">
                    <Suspense fallback={<Loader />}>
                      <PageNotFound/>
                    </Suspense>
                  </Route>
              </Switch>
          </Router>
      </ThemeProvider>
    );
};
