import React from "react";


export const Loader = () => {
    return (
        <>
            <div style={{height: '100vh', "lineHeight": '100vh', "textAlign": 'center',}}>
            {/* <div> */}
                <h1>
                    Loading...
                </h1>
            </div>
        </>
    );
};