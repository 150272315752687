/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
// import { FirstName, LastName } from "../../utils/getName";

import './About.css';

import profile from '../../assets/profile.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const About = () => {
  const classes = useStyles();
  const greetings = "Hello there!";
  const aboutme = <React.Fragment>Hello, I'm Alex, a solution developer and automation enthusiast with a background in Information Technology. I recently graduated from <a href="https://www.yorku.ca/" target="_blank" rel="noopener noreferrer">York University</a> and have experience as a 
  QA Engineer intern @ <a href="https://www.ibm.com/ca-en" target="_blank" rel="noopener noreferrer">IBM</a>.
  <br/>
  <br/>
  I am open to exploring opportunities that align with my skills and interests, whether in software engineering or other fields, and am eager to take on new challenges and apply my skills and passion in a dynamic and impactful role.</React.Fragment>
  return (
    <section id="about">
      <Container component="main" className={classes.main} maxWidth="md">
        <div className="about">
          <div className="_img"
            style={{ 
              background: "url(" + profile + ")",
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
          </div>
          <div className="_content_wrapper">
            <Typography component='h2' variant="h5">
              <TextDecrypt text={`${greetings}`} />
            </Typography>
            <p className="aboutme">
              {aboutme}
            </p>
            <a href="#contact" className="contact-btn">
              <i className="fas fa-terminal"></i>
              <Typography component='span'> Send me a message.</Typography>
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};
