/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import './Works.css';

import Portfolio from '../../assets/recentprojects/react-portfolio.png';
import Keyboard from '../../assets/recentprojects/FinishedKB7-resized.jpg'
import USBCable from '../../assets/recentprojects/CustomUSBComponents-resized.jpg'
import IBM from '../../assets/recentprojects/IBM.png';
import Lacalle from '../../assets/recentprojects/lacalle.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    { 
      id: 1,
      title: 'IBM Internship', 
      description: `Discover My Exciting Journey as a Software Development Engineer in Test (SDET) Intern at IBM: Behind-the-Scenes Stories and Lessons Learned`,
      alter: 'IBM Internship',
      image: `${IBM}`,
      href: '/blog/ibm-internship',
    },
    { 
      id: 2,
      title: 'React Portfolio', 
      description: `Developed a portfolio website utilizing React, implementing various libraries such as React Markdown to render project posts and progress updates.`,
      alter: 'React Portfolio',
      image: `${Portfolio}`,
      href: '/blog/react-portfolio',
    },
    { 
      id: 3,
      title: 'Custom Mechanical Keyboard', 
      description: `Custom-built a mechanical keyboard, integrating soldering and other techniques to enhance functionality and optimize user experience. Personalized key layout and macros for a unique and tailored user experience`,
      alter: 'Keyboard Project',
      image: `${Keyboard}`,
      href: '/blog/custom-keyboard',
    },
    { 
      id: 4,
      title: 'Custom USB Cable', 
      description: `Crafted a custom USB cable, using technical skills in electrical wiring and soldering to create a unique and personalized solution`,
      alter: 'USB Cable Project',
      image: `${USBCable}`,
      href: '/blog/usb-cable',
    },
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {projects.map((project) => (
          <div className="project" key={ project.id }>
            <div className="__img_wrapper">
              <a href={ project.href }>
                <img src={ project.image } alt={ project.alter }/>
              </a>
            </div>
            <div className="__content_wrapper">
              <a href={ project.href }>
                <h3 className="title">
                  <TextDecrypt text={ project.id + '. ' + project.title } />
                </h3>
              </a>
              <p className="description">
                { project.description }
              </p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
