import { primary } from "../components/theme/Themes";

export const logCredits = () => {
    const waveEmoji = String.fromCodePoint(0x1F44B);

    const logStyle = [
        `color: ${primary}`,
        "font-size: 2em",
        "font-weight: 300",
        "padding: 10px 0px 10px 0px",
    ].join(";");

    return console.log(
        `%c ${new Date().getFullYear()} github.com/AlexTuTruong \n ${waveEmoji}`,
        logStyle
    );
};
