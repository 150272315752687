import { useEffect, useState } from 'react';

export function useScrollState() {
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const aboutSec = document.getElementById('about');
    const worksSec = document.getElementById('works');
    // const contactSec = document.getElementById('contact');

    const onScroll = () => {
      var aboutOffsetTop = window.pageYOffset + aboutSec.getBoundingClientRect().top -150;
      var worksOffsetTop = window.pageYOffset + worksSec.getBoundingClientRect().top -150;
      var contactOffsetTop = document.body.scrollHeight - window.innerHeight;

      //DEBUG
      // console.log(aboutOffsetTop, worksOffsetTop, contactOffsetTop, window.scrollY);

      if (window.pageYOffset < aboutOffsetTop){
        setScrollPos(0);
        // console.log("section1");
      } 
      else if (window.pageYOffset >= aboutOffsetTop && window.pageYOffset < worksOffsetTop){
        setScrollPos(1);
        // console.log("section2");
      }
      else if (window.pageYOffset >= worksOffsetTop && window.pageYOffset < contactOffsetTop){
        setScrollPos(2);
        // console.log("section3");
      }
      else if (window.pageYOffset >= contactOffsetTop){
        setScrollPos(3);
        // console.log("section4");
      }
    }
    // clean up code
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return scrollPos;
}