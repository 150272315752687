import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  svgHover: {
    fill: theme.palette.foreground.default,
    "&:hover": {
        fill: theme.palette.primary.main,
    },
    transition: "all 0.5s ease",
  },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 189.7 191.5"
        className={classes.svgHover}
      >
        <g id="Layer_2">
          <g id="Layer_5">
          <polygon className="st0" points="72,52.3 125.4,138.1 103.9,138.1 72,87.4 59,107.6 37.6,107.5"/>
		      <polygon className="st0" points="69.4,121.9 59.7,138.1 19.3,138.1 29.1,121.9"/>
		      <polygon className="st0" points="140,80.9 140.1,136.9 123.5,110.2 123.5,80.9"/>
		      <polygon className="st1" points="174.4,51.5 163.5,69.8 98.8,69.8 88.4,51.5"/>
          </g>
        </g>
      </svg>
    );
};
